import React, { useState, useEffect } from "react";
import DropDown from "../../../../common/dropdown";
import ValidatedInput from "../../../../common/input/validated-input";
import Loader from "../../../../common/loader";
import { useTranslation } from "react-i18next";
import { CardBody, CardHeader, CardFooter } from "reactstrap";

const AddressForm = (props) => {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [validOrganization, setValidOrganization] = useState(true);
  const [validCity, setValidCity] = useState(true);
  const [validCountry, setValidCountry] = useState(false);
  const [validRegion, setValidRegion] = useState(false);
  const [validAddress, setValidAddress] = useState(true);
  const [validZip, setValidZip] = useState(true);
  const [validTaxId, setValidTaxId] = useState(true);
  const [sending, setSending] = useState(false);

  function onCountryClick({ target }) {
    setSelectedCountry(target.innerText);
    setSelectedRegion({ name: t("select"), id: null });
    setValidCountry(true);

    props.getRegions(target.value).then((regions) => {
      setRegions(regions);
    });
  }

  function onRegionClick({ target }) {
    setForm("regionId", target.value);
    setSelectedRegion({ name: target.innerText, id: target.value });
    setValidRegion(true);
  }

  const setForm = (prop, value) => {
    let form = Object.assign({}, props.form);
    form[prop] = value;
    props.changeNewAddressForm(form);
  };

  useEffect(() => {
    props.getCountries().then((countries) => {
      setCountries(countries);
    });
    setForm("userId", props.dbUser.userId);
    setForm("taxId", props.dbUser.taxId);
  }, []);

  function openMessageModal(e) {
    e.preventDefault();
    let messageHeader = t("ship-to-someone-else");
    let messageBody = t("ship-to-someone-else-expl");
    props.openMessageModal(messageHeader, messageBody);
  }
  function onSaveClick() {
    if (validateForm()) {
      setSending(true);
      props.saveForm(props.token);
    }
  }

  function onCloseClick() {
    props.setShowAddressForm(false);
  }

  function validateForm() {
    let isValid = true;

    if (!validOrganization) {
      isValid = false;
    }
    if (selectedCountry == "Brazil" && (!validTaxId || !props.form.taxId)) {
      setValidTaxId(false);
      isValid = false;
    }
    if (!selectedCountry) {
      setValidCountry(false);
      isValid = false;
    }
    if (!selectedRegion) {
      setValidRegion(false);
      isValid = false;
    }
    if (!validCity || !props.form.cityName) {
      setValidCity(false);
      isValid = false;
    }
    if (!validAddress || !props.form.address) {
      setValidAddress(false);
      isValid = false;
    }
    if (!validZip || !props.form.zipcode) {
      setValidZip(false);
      isValid = false;
    }

    return isValid;
  }

  if (sending) {
    return <Loader label={t("sending")} />;
  }

  return (
    <>
      <CardHeader>{t("address-new")}</CardHeader>
      <CardBody>
        <div className="cart form-group">
          <label>
            {t("your-name")}
            <div style={{ paddingBottom: "10px" }}>
              <input
                type="text"
                className="form-control col"
                disabled
                value={props.dbUser.firstName + " " + props.dbUser.lastName}
              />
              <button
                onClick={(e) => openMessageModal(e)}
                className="btn btn-light btn btn-secondary"
                style={{ textDecoration: "underline" }}
              >
                {t("ship-to-someone-else")}
              </button>
            </div>
          </label>

          <label>
            {t("organization")}
            <ValidatedInput
              type="string"
              className="form-control col"
              errorMessage={t("input-error-message")}
              enabled={true}
              required={false}
              isValid={validOrganization}
              onBlur={(_, valid) => setValidOrganization(valid)}
              onChange={(value) => setForm("organization", value)}
              placeholder={t("organization")}
              value={props.form.organization}
            />
          </label>
          <div>
            <label>
              {t("address")}
              <ValidatedInput
                type="string"
                className="form-control col"
                enabled={true}
                errorMessage={t("input-error-message")}
                isValid={validAddress}
                onBlur={(_, valid) => setValidAddress(valid)}
                onChange={(value) => setForm("address", value)}
                placeholder={t("address-desc")}
                value={props.form.address}
              />
            </label>
            <br />
            <label>{t("country")}</label>
            <DropDown
              className="col-md-4 mb-3 ps-4"
              enabled={true}
              items={countries}
              label={selectedCountry ? selectedCountry : t("select")}
              onClick={onCountryClick}
              separatedIndex={8}
              text="name"
              value="alpha2Code"
            />

            <label>{`${t("state")} / ${t("region")}`}</label>

            <DropDown
              className="col-md-4 mb-3 ps-4"
              items={regions}
              label={selectedRegion ? selectedRegion.name : t("select")}
              text="name"
              value="id"
              enabled={validCountry}
              onClick={onRegionClick}
            />

            <label>
              {t("city")}
              <ValidatedInput
                type="string"
                className="form-control col"
                enabled={validRegion}
                errorMessage={t("input-error-message")}
                isValid={validCity}
                onBlur={(_, valid) => setValidCity(valid)}
                onChange={(value) => setForm("cityName", value)}
                placeholder={t("city")}
                value={props.form.cityName}
              />
            </label>
            <label>
              {t("zipcode")}
              <ValidatedInput
                type="string"
                className="form-control col"
                enabled={true}
                errorMessage={t("input-error-message")}
                isValid={validZip}
                onBlur={(_, valid) => setValidZip(valid)}
                onChange={(value) => setForm("zipcode", value)}
                placeholder={t("zipcode")}
                value={props.form.zipcode}
              />
            </label>
            {selectedCountry == "Brazil" && (
              <label>
                {t("taxId")}
                <ValidatedInput
                  type="string"
                  className="form-control col"
                  errorMessage={t("input-error-message")}
                  enabled={true}
                  required={true}
                  isValid={validTaxId}
                  onBlur={(_, valid) => setValidTaxId(valid)}
                  onChange={(value) => setForm("taxId", value)}
                  placeholder={t("taxId")}
                  value={props.form.taxId}
                />
              </label>
            )}
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <button className={"btn btn-info"} onClick={onSaveClick}>
          <span>{t("save") + " " + t("address-new")}</span>
        </button>
        <button className={"btn btn-secondary"} onClick={onCloseClick}>
          <span>{t("close")}</span>
        </button>
      </CardFooter>
    </>
  );
};

export default AddressForm;

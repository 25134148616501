import React, { useState, useEffect } from "react";
import AddressList from "../../../account/components/address-list";
import UserForm from "../../../account/components/user-form";
import ValidatedInput from "../../../../components/common/input/validated-input";
import { INPUT_TYPES } from "../../../../common/constants";
import { Alert, Card, CardBody } from "reactstrap";
import withExternalJsScript from "../../../common/with-external-js-script";
import { useTranslation } from "react-i18next";
import FreePaySelect from "./free-pay-select";
import config from "../../../../cornerstone_config.json";
import CornerstonePayForm from "../../../common/cornerstone-pay-form";
import DropDown from "../../dropdown";
import { Link } from "react-router-dom";
import ipify from "ipify2";

const CheckOutForm = (props) => {
  const { t } = useTranslation();

  const [sending, setSending] = useState(false);
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(true);
  const [validDonation, setValidDonation] = useState(true);
  const [cartItemsValue, setCartItemsValue] = useState(0);
  const [selectedInterval, setSelectedInterval] = useState({
    name: "frequency-once",
    id: 1,
  });
  const [totalValue, setTotalValue] = useState(0);
  const [addressPasses, setAddressPasses] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [paypalWindowOpen, setPaypalWindowOpen] = useState(false);
  const [error, setError] = useState(null);

  const intervals = [
    { name: "frequency-once", id: 1 },
    { name: "frequency-monthly", id: 2 },
  ];

  const onIntervalClick = (e) => {
    let targetInterval = intervals.filter((i) => i.id == e.target.value)[0];
    setSelectedInterval(targetInterval);
    setForm("interval", targetInterval.name);
  };
  function validateForm() {
    if (
      props.flavor === "donate" &&
      (props.form.donation === undefined || props.form.donation < 0.1)
    )
      return false;
    if (!validDonation) return validDonation;
    return true;
  }
  useEffect(() => {
    if (props.flavor === "donate") {
      setPaypalWindowOpen(true);
    }
  }, [props.flavor]);

  useEffect(() => {
    const donVal =
      props.form.donation !== undefined && props.form.donation > 0
        ? props.form.donation
        : 0;
    const payVal = (
      Math.max(cartItemsValue - props.dbUser.freeLimit, 0) + parseFloat(donVal)
    ).toFixed(2);
    setTotalValue(payVal);
  }, [props.form.donation, cartItemsValue, props.dbUser.freeLimit]);

  function onCheckoutClick(e) {
    e.preventDefault();
    if (validateForm()) {
      if (totalValue > 0.01) {
        setPaypalWindowOpen(true);
      } else {
        setSending(true);
        props.saveCart();
      }
    }
  }

  function finishSubmit(response) {
    setForm("paymentId", response.token);
  }

  function onPolicyChecked(accepted) {
    setIsPolicyAccepted(accepted);
  }

  const setForm = (prop, value) => {
    let form = Object.assign({}, props.form);
    form[prop] = value;
    props.changeCheckoutForm(form);
  };

  useEffect(() => {
    if (props.form.caten === undefined) setForm("caten", false);
    if (props.form.cates === undefined) setForm("cates", false);
    if (!props.form.ipAddress) {
      ipify
        .ipv4()
        .then((ipv4) => setForm("ipAddress", ipv4))
        .catch((err) => console.log(err));
    }
  }, [props.form]);

  useEffect(() => {
    let localSelectedAddress =
      typeof props.addresses.find((a) => a.id === props.form.addressId) !==
        "undefined" &&
      props.addresses.find((a) => a.id === props.form.addressId).id > 0
        ? props.addresses.find((a) => a.id === props.form.addressId)
        : {};
    let localAddressPasses =
      Object.keys(localSelectedAddress).length > 0 ? true : false;
    setAddressPasses(localAddressPasses);
    setSelectedAddress(localSelectedAddress);
  }, [props.addresses, props.form.addressId]);

  useEffect(() => {
    setCartItemsValue(props.valInCart);
  }, [props.valInCart]);

  useEffect(() => {
    if (
      props.valInCart > props.dbUser.freeLimit &&
      props.form["type"] === "free"
    ) {
      setForm("type", "pay");
    }
  }, [props.valInCart, props.dbUser]);

  useEffect(() => {
    if (props.form.paymentId && props.form.paymentId.length > 1) {
      if (props.flavor === "cart") props.saveCart();
      if (props.flavor === "donate") {
        if (selectedInterval.name == "frequency-monthly") {
          props.saveRegularGiving();
        } else {
          props.saveDonate();
        }
      }
    } else if (props.form.paymentId == "") {
      setSending(false);
      setPaypalWindowOpen(false);
    }
  }, [props.form.paymentId]);

  return (
    <form
      onSubmit={(e) => {
        onCheckoutClick(e);
      }}
    >
      <fieldset>
        <div className="form-group">
          <div>
            <div className="text-relevant">
              <h2>{props.flavor === "donate" ? t("donate") : t("checkout")}</h2>
            </div>
            <UserForm />
            {props.dbUser.userId && <AddressList flavor={props.flavor} />}
            {props.flavor === "cart" &&
              props.areBooksAvailable &&
              props.dbUser.userId && (
                <Card>
                  <FreePaySelect
                    form={props.form}
                    dbUser={props.dbUser}
                    valInCart={props.valInCart}
                    setForm={setForm}
                  />
                  <CardBody>
                    <label>{t("cart-value")}</label>
                    <div
                      className="col-12 d-flex flex-wrap d-row input-group-text align-content-center"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      <strong>
                        $
                        {Math.min(
                          cartItemsValue,
                          props.dbUser.freeLimit
                        ).toFixed(2)}{" "}
                        {t("free")} | $
                        {Math.max(
                          cartItemsValue - props.dbUser.freeLimit,
                          0
                        ).toFixed(2)}{" "}
                        {t("pay")} - {t("cart-over-free-limit")}
                      </strong>
                    </div>
                    <br />
                  </CardBody>
                </Card>
              )}
            {props.dbUser.userId && (
              <Card>
                <CardBody>
                  {props.flavor === "cart" && (
                    <label>{t("cart-donation")}</label>
                  )}
                  <div
                    className="col-12 d-flex flex-wrap d-row input-group-text align-items-center"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    ${" "}
                    <ValidatedInput
                      type="string"
                      className="form-control col"
                      errorMessage={t("input-error-message")}
                      enabled={true}
                      required={false}
                      inputType={INPUT_TYPES.CURRENCY}
                      isValid={validDonation}
                      onBlur={(_, valid) => setValidDonation(valid)}
                      onChange={(value) => setForm("donation", value)}
                      placeholder={t("donate")}
                      value={props.form.donation}
                    />{" "}
                    <span className="ml-1">USD</span>
                  </div>
                  {props.flavor === "donate" &&
                    props.dbUser.regularGivings.length < 1 && (
                      <div
                        className="col-12 d-flex flex-wrap d-row input-group-text align-items-center"
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        <DropDown
                          className="col-md-4"
                          enabled={true}
                          items={intervals}
                          label={
                            selectedInterval
                              ? selectedInterval.name
                              : t("select")
                          }
                          text="name"
                          value="id"
                          onClick={onIntervalClick}
                          translateItems
                        />
                        {selectedInterval && selectedInterval.id == 2 && (
                          <span>{t("frequency-monthly-details")}</span>
                        )}
                      </div>
                    )}
                  {props.flavor === "donate" &&
                    props.dbUser.regularGivings.length > 0 && (
                      <div
                        className="alert alert-secondary small"
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {t("regular-giving-instructions")}
                        <Link to={"/account"}>{t("account")}</Link>
                      </div>
                    )}
                  <br />
                  <label className="mb-3">
                    <input
                      type="checkbox"
                      onChange={(e) => onPolicyChecked(e.target.checked)}
                      checked={isPolicyAccepted}
                    />
                    {t("privacy-policy-agreedment")}
                    <a
                      href="/about/privacy-policy"
                      target="_blank"
                      className="btn btn-light btn btn-secondary"
                      style={{ textDecoration: "underline" }}
                    >
                      {t("privacy-policy")}
                    </a>
                  </label>

                  {paypalWindowOpen && (
                    <div className="cornerstone mb-4">
                      <CornerstonePayForm
                        parentActionHandle={finishSubmit}
                        parentValidateForm={validateForm}
                        amount={totalValue}
                        selectedInterval={selectedInterval}
                      />
                    </div>
                  )}
                  {error && <Alert color="danger">{error}</Alert>}
                  {!paypalWindowOpen && (
                    <button
                      className={"btn col-12 btn-info"}
                      disabled={
                        (props.flavor === "cart" && !props.areBooksAvailable) ||
                        !isPolicyAccepted ||
                        !addressPasses ||
                        sending
                      }
                      onClick={onCheckoutClick}
                    >
                      <span>
                        {t("checkout")}
                        {sending && (
                          <i className="fa fas fa-spinner fa-spin ml-3"></i>
                        )}
                      </span>
                    </button>
                  )}
                  {!addressPasses && (
                    <p style={{ color: "red" }}>{t("cart-select-address")}</p>
                  )}
                </CardBody>
              </Card>
            )}
          </div>
        </div>
      </fieldset>
    </form>
  );
};

const CheckoutFormWithScript = withExternalJsScript(
  "https://cps.transactiongateway.com/token/Collect.js",
  CheckOutForm,
  {
    attributes: {
      "data-tokenization-key": config.security_key,
      "data-variant": "inline",
    },
  }
);

export default CheckoutFormWithScript;
